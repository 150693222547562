import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "./Navbar/Navbar";
import "./LandingPage.css";
import Aos from "aos";
import * as actionCreators from "../../store/actions/actions";
import "aos/dist/aos.css";
import Layout from "./Layout/Layout";
import SubLayout from "./SubLayout/SubLayout";
import TopSection from "./Banner/Banner";
import Footer from "./Footer/Footer";
import FeatureCards from "./FeatureCardsSection/FeatureCards";
import Section1 from "./Section1/Section1";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import Section4 from "./Section4/Section4";
import BackToTop from "./BackToTop/BackToTop";
import Url from "../../API/baseURL/Url";

function LandingPage(props) {

  const [docID,setDocID] = useState(null);

  useEffect(() => {
    Aos.init({
      duration: 600,
    });
    props.fetchData();
    props.selectedSubject("");

    // for previewing doc
    let queryParams = new URLSearchParams(window.location.search);
    let docId = queryParams.get("id");
    let docType = queryParams.get("mime");

    if (docId !== null) {
      setDocID(docId);
      const previewPath = `https://docs.google.com/viewer?url=${Url}api/user/preview/${docId}`;

      if(docType === 'pdf'){
        window.open(`${Url}api/user/preview/${docId}`,"_self");
      }
      else{
        window.open(previewPath,"_self");
      }
    }
    // clearing all branches from local branch except the latest one
    let branch = JSON.parse(localStorage.getItem("branch"))

    if(branch){
      
      
      if(branch.length > 0)
      { 
      // if branch in localStorage is undefined eg [null]
        if(!branch[0])
            localStorage.setItem("branch", null);
        
        else 
            localStorage.setItem("branch", JSON.stringify([branch[branch.length - 1]]));
      }
    }

    

   
  }, []);

  let feature_ref = useRef(null);
  let mobile_ref = useRef(null);

  const ScrollToFeature = () => {
    window.scrollTo(550, 530); // feature section positions
  };
  const ScrollToMobile = () => {
    mobile_ref.current.scrollIntoView({ behavior: "smooth" });
  };

  if(docID !== null){
    return null;
  }

  return (
    <>
      <Navbar onClick={ScrollToFeature} onClickMobile={ScrollToMobile} />
      <Layout>
        <SubLayout>
          <TopSection onClick={ScrollToFeature} />
          <FeatureCards forwarded_ref={feature_ref} />
        </SubLayout>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 forwarded_ref={mobile_ref} />
      </Layout>
      <BackToTop />
      <Footer />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: () => dispatch(actionCreators.fetchCourseDataAsync()),

    // to clear the searched item
    selectedSubject: (data) => dispatch(actionCreators.selectedSubject(data)),
  };
};

export default connect(null, mapDispatchToProps)(LandingPage);
