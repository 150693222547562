import React from "react";
import styles from './Footer.module.css';
import SI from "../../../assets/images/SI.svg";
import Instagram from "../../../assets/images/instagram.svg";
import Facebook from "../../../assets/images/facebook.svg";
import LinkedIn from "../../../assets/images/linkedIn.svg";
// import Github from "../../../assets/images/github.svg";
import Twitter from "../../../assets/images/twitter.svg";
import Medium from "../../../assets/images/medium.svg";

const Footer = () => {
  return (
    <div style={{background: "#546076"}}>
    <footer>
      <div className={styles.footerBlock}>
        <div className={styles.Section1}>
            <div className={styles.text1}>
                Powered By
            </div>
            <div className={styles.Si_logo}>
                <div className={styles.Si_image}>
                    <img src={SI} alt="SI_LOGO"/>
                </div>
                <a href="https://silive.in/" target="_blank" rel="noopener noreferrer">
                    <span> SOFTWARE INCUBATOR</span>
                </a>
            </div>
            <div className={styles.text2}>
                © SOFTWARE INCUBATOR 2021 All rights reserved
            </div>
        </div>

        {/* for the desktop and mobile */}
        <div className={styles.Section2}>
             <div className={styles.Section2_col_1}>
                   <a href="https://silive.in/#About-us" target="_blank" rel="noopener noreferrer">
                      <p>About Us</p>
                    </a>

                  
                   <a href="https://silive.in/#Services" target="_blank" rel="noopener noreferrer">
                      <p>Our Services</p>
                    </a>
                  
                 <a href="https://silive.in/#Clients" target="_blank" rel="noopener noreferrer">
                   <p> Our Clients</p>
                 </a>
                  
             </div>

             <div className={styles.Section2_col_2}>
                  <a href="https://silive.in/#Portfolio" target="_blank" rel="noopener noreferrer">
                    <p>Our Portfolio </p>
                  </a>
                 
               <a href="https://silive.in/#Team" target="_blank" rel="noopener noreferrer">
                  <p>Our Team </p>
                </a>
               
               <a href="https://silive.in/#Contact-us" target="_blank" rel="noopener noreferrer">
                  <p> Contact Us</p>
                </a>
               
             </div>

        </div>

        {/* for tablet */}
        <div className={styles.Section2_tab}>
             <div className={styles.Section2_tab_col_1}>
                
                    <a href="https://silive.in/#About-us" target="_blank" rel="noopener noreferrer">
                      <p>About Us</p>
                    </a>
                  
                 
                   <a href="https://silive.in/#Services" target="_blank" rel="noopener noreferrer">
                      <p>Our Services </p>
                   </a>
                 
               
             </div>

             <div className={styles.Section2_tab_col_2}>
                
                    <a href="https://silive.in/#Clients" target="_blank" rel="noopener noreferrer">
                       <p>Our Clients </p>
                    </a>
                 
                  <a href="https://silive.in/#Portfolio" target="_blank" rel="noopener noreferrer">
                    <p> Our Portfolio</p>
                  </a>
               
             </div>
             
             <div className={styles.Section2_tab_col_3}>
                 <a href="https://silive.in/#Team" target="_blank" rel="noopener noreferrer">
                      <p> Our Team</p>
                 </a>
                 <a href="https://silive.in/#Contact-us" target="_blank" rel="noopener noreferrer">
                    <p>Contact Us</p>
                 </a>
             </div>

        </div>

        <div className={styles.Section3}>
            <div className={styles.Section3_text}>Get in Touch</div>
            <div className={styles.SocialMedia_handlers}>

                <a href="https://www.instagram.com/softwareincubator/" target="_blank" rel="noopener noreferrer">
                  <img className={styles.handler_image} src={Instagram} alt="instagram" />
                </a>

                <a href="https://in.linkedin.com/company/software-incubator/home" target="_blank"  rel="noopener noreferrer">
                   <img className={styles.handler_image} src={LinkedIn} alt="linkedin" />
                </a>

                <a href="https://medium.com/software-incubator" target="_blank" rel="noopener noreferrer">
                    <img className={styles.handler_image} src={Medium} alt="medium" />
                </a>

                <a href="https://twitter.com/si_akgec" target="_blank" rel="noopener noreferrer">
                  <img className={styles.handler_image} src={Twitter} alt="twitter" />
                </a>

                <a href="https://www.facebook.com/SoftwareIncubatorAKGEC" target="_blank" rel="noopener noreferrer"> 
                  <img className={styles.handler_image} src={Facebook} alt="facebook" />
                </a> 

                {/* <a href="https://github.com/Software-Incubator" target="_blank" rel="noopener noreferrer">
                  <img className={styles.handler_image_last} src={Github} alt="github" />
                </a> */}
            </div>
        </div> 

        </div>

         
     
    </footer>


      <div className={styles.Space_for_icon}>
          
      </div>

    </div>
  );
};

export default Footer;
