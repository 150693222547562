import React from "react";
import DropdownFilter from "../../../Components/Dropdowns/Content/Landing/dropdown";
import "./Banner.css";
import {Link} from 'react-router-dom'
import { connect } from "react-redux";
import { message} from 'antd';
// import { SmileOutlined } from '@ant-design/icons';
import DownArrow from '../../../assets/images/down.svg';

const TopSection = (props) => {


  const [warning,setWarning] = React.useState(true);

  let localStorageBranch = JSON.parse(localStorage.getItem('branch'))
  // console.log(localStorageBranch)
  if(!localStorageBranch) localStorageBranch = [];
  // console.log(localStorageBranch)
 
  const rippleEffect = (event)=>{
    const button = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");

    const ripple = button.getElementsByClassName("ripple")[0];

    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
  }

  const openNotification = (event) => {

    rippleEffect(event);
    // notification.open({
    //   message: 'Hey there. Welcome to Bytepad 2.0! ',
      
    //   description:
    //     'Please, Select a branch to continue',
    //   icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    // });
    setWarning(false);

    message.warning('Please, Select a branch to continue!');

    setTimeout(()=>{
      setWarning(true)
    },3000)
    
    // by default, warning duration is 3s 

  };

  return (
    <div className="home">
      <div className="home-top">
        <div className="home-top-heading">
          Find all previous year Question Papers with Solution in one place
        </div>
        <div className="home-top-subHeading">
          Please choose Course & Branch to continue.
        </div>
        <div className="home-top-dropdownsSection">
          <div className="home-top-dropdownsSection-dropdown">
            
            <DropdownFilter 
                heading={"Select Course"}
                selectedOption={props.selectedCourse.course}
                options={props.courseData}/>

            <DropdownFilter
                 heading={"Select Branch"}
                 selectedOption={props.selectedBranches[props.selectedBranches.length - 1]}
                 options={props.branchList}/>

          </div>

          {localStorageBranch.length ?  <Link to="/content"> 
             <div  className="home-top-dropdownsSection-Button">
                <span>Enter Library</span> 
             </div>
          </Link> :  
             <div onClick={warning ? (e)=>openNotification(e) : rippleEffect} className="home-top-dropdownsSection-Button">
                <span>Enter Library</span> 
             </div>
          }

          <div className="Banner-downArrow">
            <img onClick={props.onClick} className='bouncy' src={DownArrow} alt="down Array" />
          </div>
         

        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    branchList: state.filter.displayBranches,
    selectedCourse: state.filter.selectedCourse,
    courseData: state.filter.courseData,
    selectedBranches:state.filter.selectedBranches,
    subjectList:state.filter.subjectList,
  };
};

export default connect(mapStateToProps, null)(TopSection);

