import React,{lazy,Suspense} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {Spin} from 'antd';
import { HashRouter } from "react-router-dom";
import ContentPage from "./routes/ContentPage/ContentPage";
import LandingPage from "./routes/LandingPage/LandingPage";
const Login = lazy(() => import('./routes/Admin/Login/Login'));
const ViewPapers = lazy(() => import('./routes/Admin/ViewPapers/ViewPapers'));
const AddPapers = lazy(() => import('./routes/Admin/AddPapers/AddPapers'));
const EditFields = lazy(() => import('./routes/Admin/Edit/Edit'));
const DeleteField = lazy(() => import('./routes/Admin/DeleteField/DeleteField'));
const ResetPassword = lazy(() => import('./routes/Admin/ResetPassword/ResetPassword'));

function App() {

  const AdminGuardRoute = ({ component: Component, ...props }) => (
    <Route
      {...props}
      render={(routeProps) => {
        const item = localStorage.getItem("token");

        // check to see if the user is authenticated or not
        return item !== null ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );

  return (
    <HashRouter>
      <Suspense fallback= {
            <section style={{textAlign:"Center", height:"95vh", width:"100vw"}} className="loading">
              <Spin size="large" />
                <span style={{ marginTop: "8px" }}>
                  Your Data will be available shortly.
                </span>
            </section>}
      >
        <Switch>

          {/* User routes */}
          <Route path="/" exact component={LandingPage} />
          <Route path="/content" component={ContentPage} />
    
          {/* Admin routes   */}
          <AdminGuardRoute path="/Bytepad-siadmin/AddPapers" exact component={AddPapers} />
          <AdminGuardRoute path="/Bytepad-siadmin/ViewPapers" exact component={ViewPapers} />
          <AdminGuardRoute path="/Bytepad-siadmin/Edit" exact component={EditFields} />
          <AdminGuardRoute path="/Bytepad-siadmin/Delete" exact component={DeleteField} />

          <Route path="/Bytepad-siadmin/login" exact component={Login} />
          <Route
            path="/api/Bytepad-siadmin/resetPassword/:token"
            exact
            component={ResetPassword}
          />

          <Redirect to="/" />
        </Switch>
      </Suspense>
    </HashRouter>
  );
}

export default App;
