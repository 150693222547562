import React from "react";
import { Menu, Dropdown } from "antd";
import DownArrow from "../../../assets/images/downArrow.svg";
import sty from "./filter.module.css";
import { connect } from "react-redux";
import * as actionCreators from "../../../store/actions/actions";
import Branch from "../../Branch/Branch";
import DropdownSidebar from "../../dropdownSidebar/dropdownSidebar";

function Filter(props) {
  const [visible, setVisible] = React.useState(false);



  // React.useEffect(() => {
  //   window.onscroll = () => {
  //     // setOffset(window.pageYOffset)
  //     if(visible){
  //       setVisible(false)
  //     }
  //   }
  // }, [window.onscroll]);


  // when a course is selected
  const handleOptionClick = (item, index) => {
    setVisible(false);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // changes the selected course in redux store
    var selectedBranch = [];
    props.course.forEach((course) => {
      if (course.course === item.course) {
        selectedBranch = [course.branches[0]];
      }
    });
    props.changeCourse(item, index); // selected course

    props.fetchPapers(
      { course: item.course, branches: selectedBranch },
      selectedBranch
    );

    // changes the items in localStorage
    localStorage.setItem("course", JSON.stringify(item));
    localStorage.setItem("branch", JSON.stringify(selectedBranch));
  };

  let menuContent = null;

  if (props.heading === "Branch") {
    menuContent = <Branch />;
  } 
  else if (props.heading === "Course") {
    menuContent = props.options.map((item, index) => {
      return (
        <Menu.Item
          onClick={() => handleOptionClick(item, index)}
          key={index}
          // different classes for branch and menu
          className={
            props.selectedOptions === item.course
              ? sty.SelectedMenuItem
              : sty.MenuItem
          }
        >
          {item.course}
        </Menu.Item>
      );
    });
  } 
  else {
    menuContent = <DropdownSidebar filter={props.heading} />;
  }

  const menu = (
    <Menu className={props.branch ? sty.BranchMenu : sty.Menu}>
      {menuContent}
    </Menu>
  );

  return (
    <Dropdown
      className={props.branch ? sty.BranchDropdown : sty.Dropdown}
      overlay={menu}
      onVisibleChange={() => setVisible((prevState) => !prevState)}
      // getPopupContainer={trigger => trigger.parentElement}
      visible={visible}
      trigger={["click"]}
    >
      <div className={sty.Dropdown_filter}>
        <div className={sty.Filter}>
          {/* this is the big heading on the dropdown */}
          {props.heading === "Type" ||
          props.heading === "Exam" ||
          props.heading === "Session" ? (
            <span
              style={{
                color: "#546076",
                fontWeight: "500",
                fontSize: "15.8px",
              }}
            >
              {props.heading}
            </span>
          ) : (
            <span>{props.heading}</span>
          )}

          {/* branch will have maximun three branches on display and if more is selected, it will be followed by ... */}
          {props.branch ? (
            <span className={sty.Filter_branch}>
              {props.selectedOptions
                .filter((filteredBranch, index) => index <= 3)
                .map((branch, index) => {
                  return (
                    <span key={index} className={sty.branchSubCaption}>
                      {index >= 3 ? null : branch}
                      {index + 1 !== props.selectedOptions.length && index <= 2
                        ? ","
                        : null}
                      {index === 3 ? "..." : null}
                    </span>
                  );
                })}
            </span>
          ) : (
            <span>{props.selectedOptions}</span>
          )}
        </div>
        <img
          src={DownArrow}
          className={visible ? sty.upOutlined : sty.DownOutlined}
          alt="downarraow"
        />
      </div>
    </Dropdown>
  );
}

const mapStateToProps = (state) => {
  return {
    course: state.filter.courseData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCourse: (type, index) =>
      dispatch(actionCreators.changeSelectedCourse(type, index)),
    fetchPapers: (payload, selectedBranches) =>
      dispatch(actionCreators.fetchAsyncPapers(payload, selectedBranches)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
