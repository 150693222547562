const initialState = {
  allData: [],
  displayData: [],
  subjectList: [],

  courseData: [],
  selectedCourse: "",

  displayBranches: [],
  selectedBranches: [],

  paperTypes: [
    { contType: "Question Paper", checked: false },
    { contType: "Answer Sheets", checked: false },
  ],
  selectedPaperTypes: [],

  examTypes: [],
  // It will contain filter options 
  // for exams -> { exam: "ST-1", checked: false }
  selectedExamTypes: [],

  examYears: [],
  // It will contain filter options 
  // for exams -> { year: "2020-2021", checked: false }
  selectedSessions: [],

  subject: "",
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  const newListGeneration = (type, value) => {
    let contentTypes = state.selectedPaperTypes;
    let examTypes = state.selectedExamTypes;
    let subject = state.subject;
    let selSessions = state.selectedSessions;

    // if(subject !== null){
    //   subject = subject.toLowerCase();
    // }

    if (type === "paperType") {
      contentTypes = value;
      if (contentTypes.length === 0) {
        contentTypes = [];
        state.paperTypes.forEach((contentType) => {
          contentTypes.push(contentType.contType);
        });
      }
    } else if (type === "examType") {
      examTypes = value;
      if (examTypes.length === 0) {
        examTypes = [];
        state.examTypes.forEach((examType) => {
          examTypes.push(examType.exam);
        });
      }
    } else if (type === "subject") {
      subject = value;
    } else if (type === "session") {
      selSessions = value;
      if (selSessions.length === 0) {
        selSessions = [];
        state.examYears.forEach((examYear) => {
          selSessions.push(examYear.year);
        });
      }
    }

    if (type !== "paperType" && contentTypes.length === 0) {
      // contentTypes = ["Answer Sheets","Question Paper"];
      contentTypes = [];
      state.paperTypes.forEach((contentType) => {
        contentTypes.push(contentType.contType);
      });
    }
    if (type !== "examType" && examTypes.length === 0) {
      // examTypes = ["ST-1","ST-2","PUT","UT"];
      examTypes = [];
      state.examTypes.forEach((examType) => {
        examTypes.push(examType.exam);
      });
    }
    if (type !== "session" && selSessions.length === 0) {
      // selSessions = ["2024-2025", "2021-2022", "2020-2021"];
      selSessions = [];
      state.examYears.forEach((examYear) => {
        selSessions.push(examYear.year);
      });
    }

    // console.log("___________________________________");
    // console.log(contentTypes);
    // console.log(examTypes);
    // console.log(selSessions);
    // console.log("___________________________________");

    let newDisplayData = [];
    // console.log(`=====>${subject}<=====`);
    state.allData.forEach((paper, index) => {
      // console.log(paper.subjectName);
      if (
        !subject &&
        contentTypes.includes(paper.paperType) &&
        examTypes.includes(paper.exam) &&
        selSessions.includes(paper.session)
      ) {
        newDisplayData.push(paper);
      } else if (
        paper.subjectName.indexOf(subject) !== -1 &&
        contentTypes.includes(paper.paperType) &&
        examTypes.includes(paper.exam) &&
        selSessions.includes(paper.session)
      ) {
        newDisplayData.push(paper);
      }
    });

    return newDisplayData;
  };

  // This function generates the list of documents to
  // be displayed to user
  // This func. is triggered when currently selected
  // branch selected is changed
  const newListGeneration2 = (allDocs, subjectList) => {
    let contentTypes = state.selectedPaperTypes;
    let examTypes = state.selectedExamTypes;
    let subject = state.subject;
    let selSessions = state.selectedSessions;
    let newDisplayData = [];

    // console.log("*********************************");
    // console.log(contentTypes);
    // console.log(examTypes);
    // console.log(selSessions);
    // console.log(subject);
    // console.log("*********************************");

    if (subject) {
      if (subjectList.includes(subject)) {
      } else subject = "";
    }

    if (
      contentTypes.length === 0 &&
      examTypes.length === 0 &&
      selSessions.length === 0 &&
      !subject
    ) {
      return allDocs;
    }

    if (contentTypes.length === 0) {
      contentTypes = [];
      state.paperTypes.forEach((contentType) => {
        contentTypes.push(contentType.contType);
      });
    }
    if (examTypes.length === 0) {
      examTypes = [];
      state.examTypes.forEach((examType) => {
        examTypes.push(examType.exam);
      });
    }
    if (selSessions.length === 0) {
      selSessions = [];
      state.examYears.forEach((examYear) => {
        selSessions.push(examYear.year);
      });
    }

    // console.log("AFTER PUSHING");
    // console.log("*********************************");
    // console.log(contentTypes);
    // console.log(examTypes);
    // console.log(selSessions);
    // console.log("*********************************");

    allDocs.forEach((paper) => {
      // console.log(paper.subjectName)
      if (
        !subject &&
        contentTypes.includes(paper.paperType) &&
        examTypes.includes(paper.exam) &&
        selSessions.includes(paper.session)
      ) {
        newDisplayData.push(paper);
      } else if (
        paper.subjectName.indexOf(subject) !== -1 &&
        contentTypes.includes(paper.paperType) &&
        examTypes.includes(paper.exam) &&
        selSessions.includes(paper.session)
      ) {
        newDisplayData.push(paper);
      }
    });

    return newDisplayData;
  };

  switch (action.type) {
    case "FETCH_ALL_DATA":
      let SelectedCourse = null;
      // console.log(action.data[0]);

      if (
        typeof localStorage.getItem("course") === "undefined" ||
        localStorage.getItem("course") === "undefined"
      ) {
        localStorage.setItem("course", "");
      }

      SelectedCourse = localStorage.getItem("course")
        ? JSON.parse(localStorage.getItem("course"))
        : null;

      if (SelectedCourse) {
        if (typeof SelectedCourse["course"] !== "undefined") {
          // stores the existing local storage
        } else SelectedCourse = null;
      } else SelectedCourse = null;

      // console.log(SelectedCourse,3);

      if (!SelectedCourse) {
        SelectedCourse = action.data[0];
        localStorage.setItem("course", JSON.stringify(SelectedCourse));
      }

      let SelectedBranches =
        typeof localStorage.getItem("branch") !== "undefined"
          ? JSON.parse(localStorage.getItem("branch"))
          : null;

      if (!SelectedBranches) {
        SelectedBranches = [];
      }

      // by default branches appear for the first selected course
      let branch = [{ name: "All Branches", checked: false }];

      SelectedCourse.branches.forEach((branchName) => {
        branch.push({ name: branchName, checked: false });
      });

      return {
        ...state,
        courseData: action.data,
        selectedCourse: SelectedCourse, // by default the first course is selected if localStorage is empty
        selectedBranches: SelectedBranches,
        displayBranches: branch, // default
      };

    case "FETCH_PAPERS":
      let newSessionList = [];

      action.data.sessions.forEach((year) => {
        let prevYearState = [...state.examYears];
        let foundExamYear = false;
        for (let idx in prevYearState) {
          if (prevYearState[idx].year === year) {
            foundExamYear = true;
            newSessionList.push({
              year: year,
              checked: prevYearState[idx].checked,
            });
            break;
          }
        }
        if (!foundExamYear) {
          newSessionList.push({
            year: year,
            checked: false,
          });
        }
      });

      let newExamTypeList = [];

      action.data.exams.forEach((examType) => {
        let prevExamState = [...state.examTypes];
        let foundExamType = false;
        for (let idx in prevExamState) {
          if (prevExamState[idx].exam === examType) {
            foundExamType = true;
            newExamTypeList.push({
              exam: examType,
              checked: prevExamState[idx].checked,
            });
            break;
          }
        }
        if (!foundExamType) {
          newExamTypeList.push({
            exam: examType,
            checked: false,
          });
        }
      });

      return {
        ...state,
        allData: action.data.documents,
        examYears: newSessionList,
        examTypes: newExamTypeList,
        subjectList: action.data.subjects,
        selectedBranches: action.selectedBranches,
        subject:
          action.data.subjects.indexOf(state.subject) !== -1
            ? state.subject
            : "",
        displayData: newListGeneration2(
          action.data.documents,
          action.data.subjects
        ),
        isLoading: false,
      };

    case "CHANGE_SELECTED_COURSE":
      let newBranchDisplay = [
        {
          name: "All Branches",
          checked:
            state.courseData[action.index].branches.length === 1 ? true : false,
        },
      ];

      // loop to make ab object for all the brances and by default unchecked

      state.courseData[action.index].branches.forEach((branch, index) => {
        let obj = {
          name: branch,
          checked: index === 0 ? true : false,
        };
        newBranchDisplay.push(obj);
      });

      return {
        ...state,
        selectedCourse: action.data,
        selectedBranches: [],
        displayBranches: newBranchDisplay,
      };

    case "CLEAR_ALL_FILTERS":
      let newPaperTypes = [...state.paperTypes];
      newPaperTypes.map((paperType) => {
        paperType.checked = false;
        return null;
      });
      let newExamTypes = [...state.examTypes];
      newExamTypes.map((examType) => {
        examType.checked = false;
        return null;
      });
      let newExamYearsList = [...state.examYears];
      newExamYearsList.map((examYear) => {
        examYear.checked = false;
        return null;
      });

      return {
        ...state,
        paperTypes: newPaperTypes,
        examTypes: newExamTypes,
        examYears: newExamYearsList,
      };

    case "CLEAR_ALL_CONTENTTYPES":
      let newPaperTypesList = [...state.paperTypes];
      newPaperTypesList.map((paperType) => {
        paperType.checked = false;
        return null;
      });
      return {
        ...state,
        paperTypes: newPaperTypesList,
      };

    case "CLEAR_ALL_EXAMTYPES":
      let newExamTypesList = [...state.examTypes];
      newExamTypesList.map((examType) => {
        examType.checked = false;
        return null;
      });
      return {
        ...state,
        examTypes: newExamTypesList,
      };

    case "CLEAR_ALL_SESSIONFILTERS":
      let newExamYearsList2 = [...state.examYears];
      newExamYearsList2.map((examYear) => {
        examYear.checked = false;
        return null;
      });
      return {
        ...state,
        examYears: newExamYearsList2,
      };

    case "CHANGE_PAPER_TYPE":
      return {
        ...state,
        selectedPaperTypes: action.data,
        displayData: newListGeneration("paperType", action.data),
      };

    case "CHANGE_EXAM_TYPE":
      return {
        ...state,
        selectedExamTypes: action.data,
        displayData: newListGeneration("examType", action.data),
      };

    case "CHANGE_SESSION":
      return {
        ...state,
        selectedSessions: action.data,
        displayData: newListGeneration("session", action.data),
      };

    case "SELECTED_SUBJECT":
      return {
        ...state,
        subject: action.data,
        displayData: newListGeneration("subject", action.data),
      };

    case "HANDLE_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };

    case "CLEAR_DOCS":
      return {
        ...state,
        allData: [],
        displayData: [],
        selectedBranches: [],
      }

    default:
      return state;
  }
};

export default reducer;
