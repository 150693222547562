import React from "react";
import styles from "./Navbar.module.css";
// import { Link } from "react-router-dom";
import logo from "../../../assets/images/BytepadLogo.svg";
import TextLogo from "../../../assets/images/Bytepad.svg";

export default function LandingNavbar(props) {
  const navbarClass = ["LandingNavbar"];

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (scrollPosition > 20 && navbarClass.length === 1) {
    navbarClass.push("boxShadow");
  }
  if (scrollPosition < 20 && navbarClass.length === 2) {
    navbarClass.pop();
  }

  var TabletView = window.matchMedia( "(max-width: 768px)" );

  return (
    <nav className={navbarClass.join("")}>
      <span className={styles.logo}>
        <img className={styles.logo_image} src={logo} alt="logo" />
        <img className={styles.logo_image} src={TextLogo} alt="text-logo" />
      </span>

      <div className={styles.navbarHeading}>
        <span onClick={props.onClick} 
              className={styles.feature_Text}>

          Features
        </span>

        <span onClick={TabletView.matches ?
                      (e)=>{ e.preventDefault();
                      window.location.href='https://play.google.com/store/apps/details?id=in.silive.bo';
                      } 
                      :props.onClickMobile} className={styles.getMobile}>
          <span> </span>
        </span>
      </div>
    </nav>
  );
}
