import React, { Component } from "react";
// import BytepadLogo from "../../../assets/images/BytepadLogo.svg";
import styles from "./BackToTop.module.css";
import ArrowUp from "../../../assets/images/ArrowUp.svg";
import { Modal } from "react-bootstrap";
import QRCode from "qrcode.react";

class BackToTop extends Component {
  state = {
    downloadVisiblity: false,
    arrowVisibility: false,
    show: false,
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      this.toggleVisibility();
    });
  }


  componentWillUnmount() {
    document.removeEventListener('scroll', () => {
      this.toggleVisibility();
    });
}

  handleClose = () => {
    this.setState({ show: false });
  }
  handleShow = () => { 
    this.setState({ show: true });
  }

  toggleVisibility = () => {
    // console.log(window.pageYOffset);

    if (window.innerWidth >= 1100) {
      if (window.pageYOffset > 200) {
        if (window.pageYOffset < 1800) {
          this.setState({
            downloadVisiblity: true,
            arrowVisibility: true,
          });
        } else {
          this.setState({
            downloadVisiblity: false,
            arrowVisibility: true,
          });
        }
      } else {
        this.setState({
          downloadVisiblity: false,
          arrowVisibility: false,
        });
      }
    } else if (window.innerWidth < 1100 && window.innerWidth >= 581) {
      if (window.pageYOffset > 200) {
        if (window.pageYOffset < 2700) {
          this.setState({
            downloadVisiblity: true,
            arrowVisibility: true,
          });
        } else {
          this.setState({
            downloadVisiblity: false,
            arrowVisibility: true,
          });
        }
      } else {
        this.setState({
          downloadVisiblity: false,
          arrowVisibility: false,
        });
      }
    } else if (window.innerWidth < 581) {
      if (window.pageYOffset > 200) {
        if (window.pageYOffset < 2400) {
          this.setState({
            downloadVisiblity: true,
            arrowVisibility: true,
          });
        } else {
          this.setState({
            downloadVisiblity: false,
            arrowVisibility: true,
          });
        }
      } else {
        this.setState({
          downloadVisiblity: false,
          arrowVisibility: false,
        });
      }
    }
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <>
        <div className={styles.scrollToTop}>
          {/* {this.state.downloadVisiblity ? (
            <div
              className={`${styles.downloadContainer} ${styles.fadeAnimate}`}
            >
              <img src={BytepadLogo} />
              <div className={styles.textContent}>
                Get Bytepad for android device
              </div>
              <div onClick={this.handleShow} className={styles.downloadButton}>DOWNLOAD</div>
            </div>
          ) : null} */}

          {this.state.arrowVisibility ? (
            <div
              onClick={this.scrollToTop}
              className={`${styles.ArrowUp} ${styles.fadeAnimate}`}
            >
              <img className={styles.arrowUp} src={ArrowUp} alt="Top"/>
              {/* <span style={{color:'white'}}>TOP</span> */}
            </div>
          ) : null}
        </div>

        <Modal show={this.state.show} onHide={this.handleClose} centered>
          <div className="qrModal">
            <h5>Download Bytepad</h5>
            <i class="fas fa-times" onClick={this.handleClose} />
            <div className="scanner">
              <QRCode width={100} value="http://facebook.github.io/react/" />
            </div>
            <h6 className="small">Scan the Qr code to download</h6>
            <h6 className="small">Bytepad for android device.</h6>
          </div>
        </Modal>
      </>
    );
  }
}

export default BackToTop;
