import React from "react";
import Download from "../../../assets/images/LandingDownload.png";
import Papers from "../../../assets/images/Papers.png";
import Session from "../../../assets/images/Session.png";
import "./FeatureCards.css";
import BackFrame1 from "../../../assets/images/BackFrame1.svg";
import BackFrame2 from "../../../assets/images/BackFrame2.svg";
import BackFrame3 from "../../../assets/images/BackFrame3.svg";

const FeatureCards = ({ forwarded_ref }) => {
  return (
    <div ref={forwarded_ref} className="home-top-records">
      <div className="home-top-records-downloads">
        <img alt="Bytepad-frame" className="backFrame" src={BackFrame1} />
        <div className="home-top-records-downloads-image">
          <img alt="Bytepad-download-icon" src={Download} />
        </div>
        <div>
          <div className="home-top-records-downloads-heading">1000+</div>
          <div className="home-top-records-downloads-subHeading">
            App Downloads
          </div>
        </div>
      </div>

      <div className="home-top-records-downloads">
        <img className="backFrame" alt="Bytepad-frame" src={BackFrame2} />
        <div className="home-top-records-downloads-image">
          <img src={Papers} alt="Bytepad-paper-icon" />
        </div>
        <div>
          <div className="home-top-records-downloads-heading">2500+</div>
          <div className="home-top-records-downloads-subHeading">
            Papers Available
          </div>
        </div>
      </div>

      <div className="home-top-records-downloads">
        <img className="backFrame" alt="Bytepad-frame" src={BackFrame3} />
        <div className="home-top-records-downloads-image">
          <img src={Session} alt="Bytepad-session-icon" />
        </div>
        <div>
          <div className="home-top-records-downloads-heading">05+</div>
          <div className="home-top-records-downloads-subHeading">
            Sessions Available
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCards;
