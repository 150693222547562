import React, { useState, useEffect } from "react";
import BarCodeAnimation from "../../../assets/animation/Phone.json";
import "./Section4.css";
import Lottie from "react-lottie";
import BackLine4 from "../../../assets/images/BackLine4.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import googlePlay from "../../../assets/images/googlePlay.svg";
import group from "../../../assets/images/group.svg";
import QRCode from "qrcode.react";
import { Modal } from "react-bootstrap";
import triangleIcon from "../../../assets/images/block22.svg";

const Section4 = ({ forwarded_ref }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BarCodeAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div ref={forwarded_ref} className="section-4">
        <div className="section-4-animation">
          <Lottie
            options={defaultOptions}
            height={"auto"}
            width={"auto"}
            isStopped={false}
            isPaused={false}
          />
        </div>

        <div className="section-4-text-content">
          <h4 data-aos="fade-up" className="aos-animation section-4-heading">
            <img
              className="back-line aos-animation extendedLine"
              data-aos="zoom-in-right"
              data-aos-delay="800"
              src={BackLine4}
              alt="Bytepad-line-img"
            />
            {`Do more with the `}
            <br />
            {`Bytepad app`}
          </h4>
          <h6
            data-aos="fade-up"
            data-aos-delay="250"
            className="aos-animation section-4-subheading"
          >
            {`Easily access all Question papers and Solutions `}
            <br />
            {`with just one click.`}
          </h6>
          <img
            alt="Bytepad-triangle-img"
            data-aos="fade-up"
            src={triangleIcon}
            className="aos-animation illustration4"
          />
          <div className="section-4-redirectBlock web-redirect">
            <div onClick={handleShow} className="section-4-downloadButton">
              <img src={group} alt="Bytepad-group-icon" />
              <span>Download from QR Code</span>
            </div>
            <a href="https://play.google.com/store/apps/details?id=in.silive.bo">
              <img
                src={googlePlay}
                alt="Bytepad-googlePlay-img"
                className="section-4-googlePlay"
              />
            </a>
          </div>
        </div>

        <div className="section-4-redirectBlock tab-redirect">
          <div className="section-4-downloadButton">
            <img src={group} alt="Bytepad-group-icon" />
            <span>Download from QR Code</span>
          </div>
          <a href="https://play.google.com/store/apps/details?id=in.silive.bo">
            <img
              src={googlePlay}
              alt="Bytepad-googlePlay-img"
              className="section-4-googlePlay"
            />
          </a>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <div className="qrModal">
          <h5>Download Bytepad</h5>
          <i class="fas fa-times" onClick={handleClose} />
          <div className="scanner">
            <QRCode
              width={100}
              value="https://play.google.com/store/apps/details?id=in.silive.bo"
            />
          </div>
          <h6 className="small">Scan the Qr code to download</h6>
          <h6 className="small">Bytepad for android device.</h6>
        </div>
      </Modal>
    </>
  );
};

export default Section4;
