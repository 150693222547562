import React, { useEffect } from "react";
import ListAnimation from "../../../assets/animation/List.json";
import "./Section1.css";
import Lottie from "lottie-react";
import BackLine1 from "../../../assets/images/BackLine1.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import pinImg from "../../../assets/images/pin1.svg";

const interactivity = {
  mode: "scroll",
  actions: [
    {
      visibility: [0, 0.35],
      type: "seek",
      frames: [0, 40],
    },
    {
      visibility: [0.35, 0.5],
      type: "seek",
      frames: [40, 90],
    },
    {
      visibility: [0.5, 0.7],
      type: "seek",
      frames: [90, 180],
    },
  ],
};

const Section1 = () => {
  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);

  return (
    <div className="section-1">
      <div className="section-1-text-content">
        <h4 data-aos="fade-up" className="aos-animation section-1-heading">
          <img
            className="back-line aos-animation"
            data-aos="zoom-in-right"
            data-aos-delay="800"
            src={BackLine1}
            alt="Bytepad-line"
          />
          {`Find all kind of `}
          <br />
          {`Question papers with `}
          <br />
          {`their solution.`}
        </h4>
        <h6
          data-aos="fade-up"
          data-aos-delay="250"
          className="aos-animation section-1-subheading"
        >
          {`ST-1, ST-2, PUT, UT all previous year `}
          <br />
          {`Question papers are available `}
          <br />
          {`with solution on Bytepad.`}
        </h6>
        <img
          data-aos="fade-up"
          src={pinImg}
          className="aos-animation illustration1"
          alt="Bytepad-line"
        />
      </div>

      <div className="section-1-animation">
        <Lottie
          loop={1}
          animationData={ListAnimation}
          autoPlay={false}
          interactivity={interactivity}
        />
      </div>
    </div>
  );
};

export default Section1;
