import ServerService from "../../API/ServerService";

//FOR FETCHING SUBJECT LIST
export const fetchData = (data) => {
  return {
    type: "FETCH_ALL_DATA",
    data: data,
  };
};
export const fetchCourseDataAsync = () => {
  return (dispatch) => {
    ServerService.fetchCourses()
      .then((res) => {
        dispatch(fetchData(res.data));
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

//FOR SELECTED BRANCHES ["branch1","branch2","branch3" ...]
export const fetchPapers = (data, selectedBranches) => {
  return {
    type: "FETCH_PAPERS",
    data: data,
    selectedBranches: selectedBranches,
  };
};

export const fetchAsyncPapers = (data, selectedBranches) => {
  return (dispatch) => {
    // for non empty branches
    ServerService.fetchPapers(data)
      .then((res) => {
        //GETTING DOCUMENTS AS WELL AS SUBJECTS LIST
        dispatch(fetchPapers(res.data, selectedBranches));
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

//For changing selected course
export const changeSelectedCourse = (data, index) => {
  return {
    type: "CHANGE_SELECTED_COURSE",
    data: data,
    index: index,
  };
};

//FOR CHANGING FILTER TYPES
export const clearAllFilters = () => {
  return {
    type: "CLEAR_ALL_FILTERS",
  };
};
export const clearAllContentTypes = () => {
  return {
    type: "CLEAR_ALL_CONTENTTYPES",
  };
};
export const clearAllExamTypes = () => {
  return {
    type: "CLEAR_ALL_EXAMTYPES",
  };
};
export const clearAllSessionTypes = () => {
  return {
    type: "CLEAR_ALL_SESSIONFILTERS",
  };
};
export const changeExamType = (data) => {
  return {
    type: "CHANGE_EXAM_TYPE",
    data: data,
  };
};

export const changePaperType = (data) => {
  return {
    type: "CHANGE_PAPER_TYPE",
    data: data,
  };
};

export const changeSession = (data) => {
  return {
    type: "CHANGE_SESSION",
    data: data,
  };
};

export const selectedSubject = (data) => {
  return {
    type: "SELECTED_SUBJECT",
    data: data,
  };
};

// For handling-loading
export const handleLoading = (value) => {
  return {
    type: "HANDLE_LOADING",
    payload: value,
  };
};

export const clearDocs = () => {
  return {
    type: "CLEAR_DOCS",
  }
}
