import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Checkbox } from "antd";
import cross from "../../assets/images/cross.svg";
import styles from "./Branch.module.css";
import * as actionCreators from "../../store/actions/actions";

function Branch(props) {
  // state initialisation
  const [branch, setbranch] = useState(props.branchList);

  // whenever course is selected, branches are fetched from redux
  useEffect(() => {
    setbranch(props.branchList);
  }, [props.selectedCourse, props.branchList]);

  // whenever the page is reloaded, state of branch is copied from localStorage9
  useEffect(() => {
    let localStorageBranches = JSON.parse(localStorage.getItem("branch"));
    let branchCopy = [...branch];

    if (!localStorageBranches) {
      localStorageBranches = [];
    }

    // if at least one branch is selected
    if (localStorageBranches.length > 0) {
      // allBranches is a toggler for all branches checkbox
      let allBranches = true;
      branchCopy.forEach((item) => {
        if (localStorageBranches.includes(item.name)) {
          item.checked = true;
        } else if (item.name !== "All Branches") {
          allBranches = false;
        }
      });
      if (allBranches) {
        if (branchCopy.length > 0) branchCopy[0].checked = true;
      }
      setbranch(branchCopy);
    }
    // eslint-disable-next-line
  }, []);

  // Function executes when branch is selected
  const allBranchHandler = (e, branchName) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // when all branches is clicked, toggler all the branch to true
    if (branchName === "All Branches") {
      let newBranch = [...branch];
      newBranch.forEach((branch) => {
        let toggler = e.target.checked;
        branch.checked = toggler;
      });
      setbranch(newBranch);
    }
    // when its other than "All branches"
    else {
      // console.log(branchName, "executed");
      let newBranch = [...branch];

      // allBranch toggler to handle toggle for "All branches"
      var allBranch = true;

      newBranch.forEach((branch, index) => {
        // when the branch matches with the selected branch
        if (branch.name === branchName) {
          branch.checked = e.target.checked;
        }
        // if we find any of the branch as unselected, "All branch" toggler should be false
        if (!branch.checked && index !== 0) allBranch = false;
      });
      // newBranch[0] refers to "All Branch"
      newBranch[0].checked = allBranch;
      setbranch(newBranch);
    }

    let SelectedBranches = [];

    branch.forEach((branch) => {
      // we push all selected branches except "All branches"
      if (branch.checked) {
        if (branch.name !== "All Branches") SelectedBranches.push(branch.name);
      }
    });

    // setting in the localStorage
    localStorage.setItem("branch", JSON.stringify(SelectedBranches));

    const API_PAYLOAD = {
      course: props.selectedCourse.course, // selected course
      branches: SelectedBranches, // selected branches
    };

    if (SelectedBranches.length === 0) {
      props.clearDocs();
    } else {
      props.handleLoading(true);
      props.fetchPapers(API_PAYLOAD, SelectedBranches);
    }
  }; // end of branchHandler

  // function to handle "Clear"
  // 1) unselect all branches
  // 2) removes branch subHeading
  const clearHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // props.fetchPapers(
    //   { course: props.selectedCourse.course, branches: [] },
    //   []
    // );
    props.clearDocs();

    const newBranch = [...branch];
    newBranch.forEach((branch) => {
      branch.checked = false;
    });
    setbranch(newBranch);
    localStorage.setItem("branch", JSON.stringify([]));
  };

  // styles

  let clearClassCustom = null;
  let localStorageSelectedBranches = JSON.parse(localStorage.getItem("branch"));
  
  if (localStorageSelectedBranches.length === 0 || props.isLoading) {
      clearClassCustom = styles.disableClearAll;
  }
  

  return (
    <div className={styles.option}>
      <div className={styles.dropdownOptions}>
        {branch.map((branch, index) => {
          return (
            <Checkbox
              checked={branch.checked}
              key={index}
              className={styles.Checkbox}
              onChange={(e) => allBranchHandler(e, branch.name)}
            >
              <span
                className={
                  branch.checked ? styles.branchColor : styles.branchSpacing
                }
              >
                {branch.name}
              </span>
            </Checkbox>
          );
        })}
        <hr className={styles.break} />
      </div>
      <div className={`${styles.clear} ${clearClassCustom}`} 
            onClick={props.isLoading ? null : clearHandler}>
        <div>
          <img src={cross} alt="cross" />
        </div>
        <span>Clear</span>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    branchList: state.filter.displayBranches,
    selectedCourse: state.filter.selectedCourse,
    isLoading:state.filter.isLoading
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPapers: (payload, selectedBranches) =>
      dispatch(actionCreators.fetchAsyncPapers(payload, selectedBranches)),

    handleLoading: (value) => dispatch(actionCreators.handleLoading(value)),

    clearDocs: () => dispatch(actionCreators.clearDocs()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Branch);
