import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Navbar from "../../Components/Navbar/Navbar";
import "./CSS/ContentPage.css";
import styles from "./CSS/Papers.module.css";
import "./CSS/Pagination.css";
import List from "../../Components/List/List";
import * as actionCreators from "../../store/actions/actions";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Pagination } from "antd";
import EmptyPage from "../../assets/images/EmptyPage.svg";
import Footer from "../LandingPage/Footer/Footer";

const EmptyScreen = () => {
  return (
    <div className="emptyImgContainer">
      <img src={EmptyPage} alt="empty results" />
    </div>
  );
};

const ContentPage = (props) => {
  
  const [redirect, setRedirect] = React.useState(null);

  // whenever the page is going to reload, this function will run
  useEffect(() => {
    
    if (JSON.parse(localStorage.getItem("branch")) && JSON.parse(localStorage.getItem("branch")).length > 0) {
      
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  
      const API_PAYLOAD = {
        course: JSON.parse(localStorage.getItem("course"))["course"], // selected course
        branches: JSON.parse(localStorage.getItem("branch")), // selected branches
      };

      if (API_PAYLOAD["branches"].length > 0) {
        // console.log(API_PAYLOAD);
        props.fetchPapers(API_PAYLOAD, API_PAYLOAD["branches"]);
      } 
      else {
        // To handle case when someone sumbits without selecting any branch
        props.fetchPapers([], []);
      }
    } 
    else setRedirect("/");
    // eslint-disable-next-line

  }, []);

  useEffect(() => {
    props.fetchData();
    props.handleLoading(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.documents.length < pagination["min"]) {
      setPagination({ min: 0, max: paper_per_page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.documents]);

  var paper_per_page = 10;
  const [PaperPerPage] = React.useState(paper_per_page);
  const [pagination, setPagination] = React.useState({
    min: 0,
    max: PaperPerPage,
  });

  const handleChange = (value, number) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // let min = 0
    // value===1 ? min = 0 : min =  (value - 1) * PaperPerPage + 1;
    let min = (value - 1) * PaperPerPage;
    let max = min + PaperPerPage - 1;
    setPagination({ min: min, max: max });

  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  let displayData = null;
  if (props.isLoading) {
    let loadingData = [0, 1, 2, 3, 4, 5, 6, 7];
    displayData = (
      <div className={styles.Papers}>
        {loadingData.map((item, index) => {
          return <List showLoading={true} key={index} />;
        })}
      </div>
    );
  } 
  else if (!props.isLoading && props.documents.length > 0) {
    displayData = (
      <div className={styles.Papers}>
        {props.documents
          .slice(pagination["min"], pagination["max"] + 1)
          .map((item, index) => {
            return <List key={index} data={item} />;
          })}
      </div>
    );
  } 
  else if (!props.isLoading && props.documents.length === 0) {
    displayData = <EmptyScreen />;
  }

  return (
    <>
      <Navbar>
        <div className="displayArea">
          <div className="sideBar">
            <Sidebar />
          </div>

          <div className={styles.PaperSection}>
            {displayData}

            {/* No pagination if documents are unavailable */}
            {props.documents.length && !props.isLoading > 0 ? (
              <Pagination
                style={{ marginBottom: "2rem" }}
                total={props.documents.length}
                showLessItems={window.matchMedia("(max-width: 570px)") ? true : false}
                responsive={true}
                showQuickJumper={false}
                pageSize={paper_per_page}
                className={pagination}
                showSizeChanger={false}
                onChange={(value, number) => handleChange(value, number)}
              />
            ) : null}
          </div>
        </div>
      </Navbar>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    documents: state.filter.displayData,
    isLoading: state.filter.isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: () => dispatch(actionCreators.fetchCourseDataAsync()),
    fetchPapers: (payload, selectedBranches) =>
      dispatch(actionCreators.fetchAsyncPapers(payload, selectedBranches)),
    handleLoading: (value) => dispatch(actionCreators.handleLoading(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage);
