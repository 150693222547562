import React, { useEffect, useRef, useState } from "react";
import BarCodeAnimation from "../../../assets/animation/PhoneBarCode.json";
import "./Section2.css";
import Lottie from "lottie-react";
import BackLine1 from "../../../assets/images/BackLine1.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import stars from "../../../assets/images/stars.svg";
import BlockImg from "../../../assets/images/block1.svg";

const Section2 = () => {

  const [state, setState] = useState({
    autoplay: false,
    value: true,
    pause:false
  });
  let animate2 = useRef();
  
  useEffect(() => {
    // console.log('useeffect is  called')
    Aos.init({ duration: 600 });
    
    const handleScroll = () => {
      // console.log("function ran")
      
      if(animate2.current!= null && typeof animate2.current!== "undefined")
        { var x = animate2.current.offsetTop;
          var y = animate2.current.offsetHeight + animate2.current.offsetTop;
          var X = window.pageYOffset;
          var Y = window.innerHeight + X;
          if (
            (x >= X && x <= Y) ||
            (y >= X && y <= Y) ||
            x === X ||
            y === Y ||
            (X >= x && X <= y) ||
            (Y >= x && Y <= y)
           )
          {
            // console.log("section is here",state)
            if (state.value) setState((prevState)=>({ ...prevState, autoplay: true, value: false }));
            // console.log("when autoplay is true 11",state)
          } 
          else {
            // console.log("Section isnt here",state)

              if(!state.value && state.pause)
               {
                 lottieRef.current.pause();
                 setState((prevState)=>({...prevState,pause:false}))
                }              
              setState((prevState)=>({...prevState, autoplay: prevState.autoplay ? true : false,value:prevState.value ? true :false }));
              // console.log("when auto play is false 222",state.value,state.autoplay)
              
          }
        }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  
  }, [state.autoplay]);


  const lottieRef= useRef();

  return (
    <div className="section-2">
      <div className="section-2-animation" ref={animate2}>
        <Lottie
          lottieRef={lottieRef}
          animationData={BarCodeAnimation}
          loop={0}
          autoplay={state.autoplay}
        />
      </div>

      <div className="section-2-text-content">
        <div data-aos="zoom-in-up" className="section-2-newFeature">
          <img src={stars} alt="Bytepad-stars-icon" />
          <span>NEW</span>
        </div>
        <h4 data-aos="fade-up" className="aos-animation section-2-heading">
          <img
            className="back-line aos-animation"
            data-aos="zoom-in-right"
            data-aos-delay="800"
            src={BackLine1}
            alt="Bytepad-line-img"
          />
          {`Send Papers directly `}
          <br />
          {`to your device with `}
          <br />
          {`QR code.`}
        </h4>
        <h6
          data-aos="fade-up"
          data-aos-delay="250"
          className="aos-animation section-2-subheading"
        >
          {`Scan the QR code of any paper with the `}
          <br />
          {`Bytepad app and view it directly on your device.`}
        </h6>
        <img
          data-aos="fade"
          src={BlockImg}
          className="aos-animation illustration2"
          alt="Bytepad-block-img"
        />
      </div>
    </div>
  );
};

export default Section2;
