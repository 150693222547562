import React, { useState, useEffect, useRef } from "react";
import styles from "./dropdown.module.css";
import DownArrow from "../../../../assets/images/downArrow.svg";
import { connect } from "react-redux";
import * as actionCreators from "../../../../store/actions/actions";

const Dropdown = (props) => {
  const [visible, setVisible] = useState(false);

  let dropdown = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (!dropdown.current.contains(event.target)) {
        setVisible(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const toggle = () => {
    setVisible((prevState) => !prevState);
  };
  // run when a course is selected
  const CourseHandler = (item, index) => {
    props.changeCourse(item, index);
    localStorage.setItem("course", JSON.stringify(item));
    localStorage.setItem("branch", JSON.stringify([]));
    // setVisible(false);
  };
  // run when a branch is selected
  const BranchHandler = (branch) => {
    const SelectedBranch = [branch];
    const API_PAYLOAD = {
      course: props.selectedCourse.course, // selected course
      branches: SelectedBranch, // selected branches
    };
    localStorage.setItem("branch", JSON.stringify([branch]));
    // setVisible(false);

    // console.log(API_PAYLOAD,SelectedBranch);
    props.fetchPapers(API_PAYLOAD, SelectedBranch);
  };

  let menuContent = null;

  if (props.heading === "Select Branch") {
    // when there is no branches for the selected course
    // we dont want to show All branches in landing page
    // props.options[0] is "All Branches"

    if (props.options.length === 1) {
      menuContent = (
        <div disabled className={styles.MenuItem}>
          No branch available
        </div>
      );
    } else {
      menuContent = props.options.map((item, index) => {
        // condition check to remove "All Branches" from the branch list
        // As "All Branch" option comes in the 0th index from redux
        if (index !== 0)
          return (
            <div
              onClick={() => BranchHandler(item.name)}
              key={index}
              className={
                props.selectedOption === item.name
                  ? styles.SelectedMenuItem
                  : styles.MenuItem
              }
            >
              {item.name}
            </div>
          );
        else return null;
      });
    }
  } else {
    menuContent = props.options.map((item, index) => {
      return (
        <div
          onClick={() => CourseHandler(item, index)}
          key={index}
          className={
            props.selectedOption === item.course
              ? styles.SelectedMenuItem
              : styles.MenuItem
          }
        >
          {item.course}
        </div>
      );
    });
  }

  return (
    <>
      <div
        onClick={toggle}
        className={`${styles.dropdownHeader} ${styles.Dropdown_filter}`}
        ref={dropdown}
      >
        <div className={styles.Filter}>
          <span>{props.heading}</span>
          <span>{props.selectedOption}</span>
        </div>
        <img
          src={DownArrow}
          className={visible ? styles.upOutlined : styles.DownOutlined}
          alt="downarraow"
        />
        <div className={visible ? styles.Menu : styles.noMenu}>
          {menuContent}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCourse: state.filter.selectedCourse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCourse: (type, index) =>
      dispatch(actionCreators.changeSelectedCourse(type, index)),
    fetchPapers: (payload, selectedBranches) =>
      dispatch(actionCreators.fetchAsyncPapers(payload, selectedBranches)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
