import React, { useState, useEffect, useRef } from "react";
import ListAnimation from "../../../assets/animation/Section3.json";
import "./Section3.css";
import Lottie from "lottie-react";
import BackLine3 from "../../../assets/images/BackLine3.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import stars from "../../../assets/images/stars.svg";
import blockImg from "../../../assets/images/block2.svg";

const Section3 = () => {
  const [state, setState] = useState({
    autoplay: false,
    value: true,
    pause: false,
  });
  let animate3 = useRef();

  useEffect(() => {
    Aos.init({ duration: 600 });

    const handleScroll = () => {
      if (animate3.current != null && typeof animate3.current !== "undefined") {
        var x = animate3.current.offsetTop;
        var y = animate3.current.offsetHeight + animate3.current.offsetTop;
        var X = window.pageYOffset;
        var Y = window.innerHeight + X;
        if (
          (x >= X && x <= Y) ||
          (y >= X && y <= Y) ||
          x === X ||
          y === Y ||
          (X >= x && X <= y) ||
          (Y >= x && Y <= y)
        ) {
          if (state.value)
            setState((prevState) => ({
              ...prevState,
              autoplay: true,
              value: false,
            }));
        } else {
          if (!state.value && state.pause) {
            lottieRef.current.pause();
            setState((prevState) => ({ ...prevState, pause: false }));
          }

          setState((prevState) => ({
            ...prevState,
            autoplay: prevState.autoplay ? true : false,
            value: prevState.value ? true : false,
          }));
          // console.log("when auto play is false 222",state.value,state.autoplay)
        }
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [state.autoplay]);

  const lottieRef = useRef();

  return (
    <div className="section-3">
      <div className="section-3-text-content">
        <div data-aos="zoom-in-up" className="section-2-newFeature">
          <img src={stars} alt="Bytepad-stars-icon" />
          <span>NEW</span>
        </div>
        <h4 data-aos="fade-up" className="aos-animation section-3-heading">
          <img
            className="back-line aos-animation"
            data-aos="zoom-in-right"
            data-aos-delay="800"
            src={BackLine3}
            alt="Bytepad-line"
          />
          {`Share Papers through `}
          <br />
          {`links.`}
        </h4>
        <h6
          data-aos="fade-up"
          data-aos-delay="250"
          className="aos-animation section-3-subheading"
        >
          {`Directly share any paper with cloud link without `}
          <br />
          {`downloading the paper.`}
        </h6>
        <img
          alt="Bytepad-icon"
          data-aos="fade-up"
          src={blockImg}
          className="aos-animation illustration3"
        />
      </div>

      <div className="section-3-animation" ref={animate3}>
        <Lottie
          lottieRef={lottieRef}
          loop={0}
          animationData={ListAnimation}
          autoplay={state.autoplay}
        />
      </div>
    </div>
  );
};

export default Section3;
