import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import sty from "./filterNavbar.module.css";
import logo from "../../assets/images/BIcon.png";
import Bytepad from "../../assets/images/Bytepad.svg";
import SideArrow from "../../assets/images/sideArrow.svg";
import Filter from "../Dropdowns/Content/filter";
import SearchBar from "../Search/Search";
import "./SidebarFilter.css";
import "antd/dist/antd.css";

class Navbar extends Component {
  render() {
    return (
      <div className={sty.navbar}>
        <nav className={sty.Filter_navbar}>
          <div className={sty.logo}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <span className={sty.logo_image}>
                <img className={sty.logo_byte} src={logo} alt="logo" />
                <img className={sty.logo_text} src={Bytepad} alt="bytepad" />
              </span>
            </Link>
            <a href="https://play.google.com/store/apps/details?id=in.silive.bo">
              <p className={sty.logo_mobile_text}>Mobile app</p>
            </a>
          </div>

          <div className={`${sty.all_filters} SidebarFilters`}>
            <Filter
              branch={false}
              heading={"Course"}
              selectedOptions={this.props.selectedCourse.course}
              options={this.props.courseData}
            />
            <Filter
              style={{position:'relative'}}
              branch={true}
              heading={"Branch"}
              selectedOptions={this.props.selectedBranches}
            />

            <span className={sty.sidebarTab}>
              <Filter branch={false} heading={"Type"} selectedOptions={""} />
              <Filter branch={false} heading={"Exam"} selectedOptions={""} />
              <Filter branch={false} heading={"Session"} selectedOptions={""} />
              <span className={sty.blackSpace}>black space</span>
            </span>

            <span className={sty.sideArrow}>
              <img src={SideArrow} alt="arrow" />
            </span>
          </div>

          <div className={sty.SearchBarSection}>
            <SearchBar
              placeholder={"Enter subject name to search"}
              home={false}
              subjects={this.props.subjectList}
            />
          </div>
        </nav>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCourse: state.filter.selectedCourse,
    courseData: state.filter.courseData,
    selectedBranches: state.filter.selectedBranches,
    subjectList: state.filter.subjectList,
  };
};

export default connect(mapStateToProps, null)(Navbar);
