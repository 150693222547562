import axios from "./baseURL/baseURL";

class ServerService {

//_________User panel_________//

  fetchCourses() {
    return axios.get(`/api/user/home`);
  }

  fetchPapers(data) {
    return axios.post(`/api/user/getPapers`, data);
  }

  fetchSubjectData(subject) {
    return axios.get(`/api/user/subject/${subject}`);
  }

  previewDoc(id) {
    return axios.get(`/api/user/preview/${id}`);
  }
  downloadDoc(id) {
    return axios.get(`/api/user/download/${id}`);
  }

  
  //__________Admin panel________//

  Login(form) {
    return axios.post("/api/admin/login", form);
  }

  forgotPassword() {
    return axios.post("/api/admin/forgotPassword");
  }

  resetPassword(token,newPasswordData) {
    return axios.post(`/api/admin/resetPassword/${token}`,newPasswordData);
  }

  // to get data for AddPapers route before uploading
  getUploadData() {
    return axios.get("/api/admin/upload", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }

  // to upload docs
  UploadData(form) {
    return axios.post("/api/admin/upload", form, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    });
  }

  fetchAllPapers() {
    return axios.get("/api/admin/viewDocs", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }

  // To add new fields
  editFields(form) {
    return axios.post("/api/admin/addFields", form, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }

 // To delete either of course,branch,session,examtype,papertype,single paper
  DeleteTypes(data) {
    return axios.post("/api/admin/deleteDocs", data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }

  // To fetch session and exam types for deleteField
  getFields() {
    return axios.get("/api/admin/deleteFields", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }

 // DeleteField for handling deleting for session and examType
  DeleteFields(data) {
    return axios.post("/api/admin/deleteFields",data ,{
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  }

}

export default new ServerService();
