import { useState } from "react";
import ShareIcon from "../../assets/images/ShareIconSVG.svg";
import DownloadIcon from "../../assets/images/DownloadIconSVG.svg";
import styles from "./List.module.css";
import { Tooltip, message } from "antd";
import QRScanner from "../QRScanner/QRScanner";
import "antd/dist/antd.css";
import Url from "../../API/baseURL/Url";
import Skeleton from "react-loading-skeleton";

function List(props) {
  
  const [shareIconClicked, setIconClicked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  if (props.showLoading) {
    if (window.innerWidth > 850) {
      return (
        <Skeleton
          className={styles.LoadingList}
          style={{ borderRadius: "12px" }}
        />
      );
    } else {
      return (
        <div className={styles.MobileList}>
          <div className={styles.verticalSection}>
            <Skeleton style={{ marginBottom: "4px" }} height={16} />
            <Skeleton height={12} />
          </div>
        </div>
      );
    }
  }

  let docURI = props.data.documentURI;
  let split = docURI.split(".");
  let docType = split[split.length - 1];

  const previewPath = `https://docs.google.com/viewer?url=${Url}api/user/preview/${props.data.documentId}`;
  const mobileShareLink = `${Url}?id=${props.data.documentId}&name=${props.data.subjectName}&mime=${docType}`;
  const copyToClipLink = mobileShareLink.split(' ').join('_');


  // for previewing the doc when the list is clicked
  const previewDoc = () => {
    if(docType === 'pdf'){
      // alert('PDF__');
      window.open(`${Url}api/user/preview/${props.data.documentId}`)
      return;
    }
    window.open(previewPath);
  };

  // FOR DOWNLOADING DOCUMENT
  const downloadDoc = () => {
    let path = `${Url}api/user/download/${props.data.documentId}`;
    window.location.href = path;
  };

  // For social-sharing options in mobile-view
  const handleMobileShare = () => {
    const shareData = {
      title: "Bytepad | Check this out",
      text: `${props.data.subjectName}`,
      url: `${mobileShareLink}`,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const handleCopying = () => {
    setIsCopied(true);
    if (!isCopied) {
      message.success({ content: "Link Copied!", duration: 2 });
    }
  };
  const handleVisibility = () => {
    setIconClicked(!shareIconClicked);
    setTimeout(() => {
      setIsCopied(false);
    }, 500);
  };

  return (
    <>
      <div className={styles.List}>
            
        <Tooltip 
          arrowPointAtCenter={false}
          title="Click to preview document" 
          zIndex={1001}>
          <div
            onClick={previewDoc}
            className={styles.List_section1}
            style={{ cursor: "pointer" }}

          >
            {props.data.subjectName}
          </div>
        </Tooltip>

        <div className={styles.List_section2}>
          <div className={styles.List_section3}>
            <span>
              {props.data.paperType === "Answer Sheets"
                ? "Solution"
                : "Question"}
            </span>
            <span>
              {props.data.semesterType === "EVEN" ? "Even Sem" : "Odd Sem"}
            </span>
            <span>{props.data.exam}</span>
            <span>{props.data.session}</span>
          </div>
          <div className={styles.List_section4}>
            <Tooltip
              zIndex={999}
              onVisibleChange={handleVisibility}
              color="white"
              trigger="click"
              title={
                <QRScanner
                  isCopied={isCopied}
                  link={copyToClipLink}
                  handleCopying={handleCopying}
                />
              }
            >
              <div
                style={
                  shareIconClicked
                    ? { backgroundColor: "#EDF0F5", borderRadius: "6px" }
                    : null
                }
                className={styles.Icon}
              >
                <img src={ShareIcon} alt="share" />
              </div>
            </Tooltip>

            <div className={styles.Icon} onClick={downloadDoc}>
              <img
                title="Download document"
                src={DownloadIcon}
                alt="download"
              />
            </div>
          </div>
        </div>
      </div>

      {/* FOR MOBILE VIEW */}
      <div className={styles.MobileList}>
        <div className={styles.verticalSection}>
          <div onClick={previewDoc}>
            {props.data.subjectName}

            {props.data.paperType === "Answer Sheets" ? (
              <span className={styles.mobPaperType}>{` (Solution)`}</span>
            ) : (
              <span className={styles.mobPaperType}>{` (Question)`}</span>
            )}
          </div>
          <div className={styles.bottomSection}>
            <span className={styles.paperType}>
              {props.data.paperType === "Answer Sheets"
                ? "Solution"
                : "Question"}
            </span>
            <span>
              {props.data.semesterType === "EVEN" ? "Even Sem" : "Odd Sem"}
            </span>
            <span>{props.data.exam}</span>
            <span>{props.data.session}</span>
          </div>
        </div>
        <div className={styles.icons}>
          <img onClick={handleMobileShare} src={ShareIcon} alt="share" />
          <img src={DownloadIcon} alt="download" onClick={downloadDoc} />
        </div>
      </div>
    </>
  );
}

export default List;
