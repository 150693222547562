import { React, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import styles from "./Search.module.css";
import cross from "../../assets/images/cross.svg";
import * as actionCreators from "../../store/actions/actions";
import SearchIcon from "../../assets/images/search.svg";

function SearchBar(props) {
  
  const list = props.subjects;
  const [isOpen, setOpen] = useState(false);
  const [query, setQuery] = useState("");

  // defining useref hook
  let searchNode = useRef();
  let inputRef = useRef();
  let searchResults = useRef();


  // this is for useref to toggle the search bar list when clicked outside of search bar component
  useEffect(() => {
    let handler = (event) => {
      if (!searchNode.current.contains(event.target)) {
        inputRef.current.blur();
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  useEffect(() => {
    if (!props.selected_subject) {
      setQuery("");
    }
  }, [props.selected_subject]);

  // search function definition
  const filteredSearch = (subjects, query) => {
    // give all results when there is no query
    if (!query) {
      return subjects;
    }

    // else covert both to lowercase and match
    return subjects.filter((sub) => {
      const subjectName = sub.toLowerCase();
      return subjectName.includes(query.toLowerCase());
    });
  };

  // calling the search function
  const filteredSubject = filteredSearch(list, query);

  const InputHandler = (event) => {
    setQuery(event.target.value);

    searchResults.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // if input is back to empty, fetch all papers without searching cond.
    if (!event.target.value) {
      props.selectedSubject("");
    }
  };

  const EnterHandler = (event) => {
    if (event.key === "Enter" && query) {
      props.selectedSubject(query.toUpperCase());
      setOpen(false);
      inputRef.current.blur();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  // onclick handler on clicking the subject list
  const SubjectHandler = (subject) => {
    setQuery(subject);

    // sending the selected subject to redux store
    props.selectedSubject(subject.toUpperCase());

    // toggle off the results
    setOpen(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // calls when the close button is clicked
  // clears input value and fetch all papers without searching cond.
  const clearSearch = () => {
    setQuery("");
    props.selectedSubject("");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  let content_page = (
    <div ref={searchNode} className={styles.search}>
      <input
        ref={inputRef}
        className={styles.SearchBar}
        placeholder={props.placeholder}
        onFocus={() => {
          setOpen(true);
        }}
        onChange={(event) => InputHandler(event)}
        onKeyPress={(event) => EnterHandler(event)}
        value={query}
        type="search"
      />

      <span
        className={
          query || props.selected_subject ? styles.crossIcon : styles.searchIcon
        }
      >
        {query || props.selected_subject ? (
          <img
            src={cross}
            onClick={clearSearch}
            className={styles.closeIcon}
            style={{ color: "#F54343" }}
            alt="Bytepad-search-remove"
          />
        ) : (
          <img src={SearchIcon} alt="search" className={styles.searchIcon} />
        )}
      </span>

      <ul
        ref={searchResults}
        className={isOpen ? styles.searchDropdown : styles.displayNone}
      >
        {filteredSubject.length === 0 && query ? (
          // empty search result
          <div className={styles.emptyResult}>
            <p className={styles.emptyResult_text}>
              Empty search results, Try searching with a different subject name
              or code
            </p>
          </div>
        ) : filteredSubject.length ? (
          filteredSubject.map((subject, index) => (
            <li
              key={index}
              onClick={() => {
                SubjectHandler(subject);
              }}
            >
              {subject}
            </li>
          ))
        ) : (
          <div className={styles.emptyResult}>
            <p className={styles.emptyResult_text}> No Subjects available</p>
          </div>
        )}
      </ul>
    </div>
  );

  return <div>{content_page}</div>;
}

const mapStateToProps = (state) => {
  return {
    selected_subject: state.filter.subject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectedSubject: (data) => dispatch(actionCreators.selectedSubject(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
