import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as styles from "./Sidebar.module.css";
import * as actionCreators from "../../store/actions/actions";
import CrossIcon from "../../assets/images/cross.svg";
import { Checkbox } from "antd";

const Sidebar = (props) => {
  const [sessions, setSessions] = useState(props.sessionList);
  const [examTypes, setExamTypes] = useState(props.examTypeList);
  const [contentTypes, setContentTypes] = useState(props.contentTypeList);

  const [showAllSessions, setShowAllSessions] = useState(false);
  const handleClose = () => setShowAllSessions(false);
  const handleShow = () => setShowAllSessions(true);

  useEffect(() => {
    setSessions(props.sessionList);
    setExamTypes(props.examTypeList);
    setContentTypes(props.contentTypeList);
  }, [props.sessionList, props.examTypeList, props.contentTypeList]);

  const changeHandler = (e, type, subType) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (type === "ContentType") {
      let newList = [...contentTypes];
      let toggleType = subType;

      newList.map((contentType) => {
        if (contentType.contType === toggleType) {
          contentType.checked = e.target.checked;
        }
        return null;
      });

      let selectedContentType = [];
      newList.map((contenttype) => {
        if (contenttype.checked) {
          selectedContentType.push(contenttype.contType);
        }
        return null;
      });

      props.changeContentType(selectedContentType);
      setContentTypes(newList);
    }

    // FOR HANDLING EXAM-TYPE CHANGES
    else if (type === "ExamType") {
      let newList = [...examTypes];
      let toggleType = subType;

      newList.map((examType) => {
        if (examType.exam === toggleType) {
          examType.checked = e.target.checked;
        }
        return null;
      });

      let selectedExamTypes = [];
      newList.map((examType) => {
        if (examType.checked) {
          selectedExamTypes.push(examType.exam);
        }
        return null;
      });

      props.changeExamType(selectedExamTypes);
      setExamTypes(newList);
    }

    //FOR HANDLING SESSION-CHANGES
    else if (type === "Session") {
      let newSessions = [...sessions];
      let toggleYear = subType;

      newSessions.map((session) => {
        if (session.year === toggleYear) {
          session.checked = e.target.checked;
        }
        return null;
      });

      let selectedSessions = [];
      newSessions.map((session) => {
        if (session.checked) {
          selectedSessions.push(session.year);
        }
        return null;
      });

      props.changeSession(selectedSessions);
      setSessions(newSessions);
    }
  };

  const clearAllFilters = () => {
    props.changeContentType([]);
    props.changeExamType([]);
    props.changeSession([]);
    props.clearAllFilters();
  };

  let sessionsList = [...sessions];
  if (!showAllSessions) {
    sessionsList = sessions.slice(0, 5);
  }

  let customClass = null;
  if (
    props.selectedSesionList.length === 0 &&
    props.selectedExamTypeList.length === 0 &&
    props.selectedContentTypeList.length === 0
  ) {
    customClass = styles.disableClearAll;
  }

  return (
    <>
      <h6 className={styles.optionHead}>Type</h6>
      {contentTypes.map((contentType, index) => {
        return (
          <Checkbox
            key={index}
            checked={contentType.checked}
            onChange={(e) =>
              changeHandler(e, "ContentType", contentType.contType)
            }
            className={styles.option}
            style={
              contentType.checked ? { color: "#4385F5" } : { color: "#546076" }
            }
          >
            {contentType.contType}
          </Checkbox>
        );
      })}

      <h6 style={{ marginTop: "19px" }} className={styles.optionHead}>
        Exam
      </h6>
      {examTypes.map((examType, index) => {
        return (
          <Checkbox
            key={index}
            checked={examType.checked}
            onChange={(e) => changeHandler(e, "ExamType", examType.exam)}
            className={styles.option}
            style={
              examType.checked ? { color: "#4385F5" } : { color: "#546076" }
            }
          >
            {examType.exam}
          </Checkbox>
        );
      })}

      <h6 style={{ marginTop: "19px" }} className={styles.optionHead}>
        Session
      </h6>
      {sessionsList.map((session, index) => {
        return (
          <Checkbox
            key={index}
            checked={session.checked}
            onChange={(e) => changeHandler(e, "Session", session.year)}
            className={styles.option}
            style={
              session.checked ? { color: "#4385F5" } : { color: "#546076" }
            }
          >
            {session.year}
          </Checkbox>
        );
      })}

      {showAllSessions && sessions.length > 5 ? (
        <h6 className={styles.moreOption} onClick={handleClose}>
          View Less...
        </h6>
      ) : null}

      {!showAllSessions && sessions.length > 5 ? (
        <h6 className={styles.moreOption} onClick={handleShow}>
          View All...
        </h6>
      ) : null}

      <hr style={{ width: "100%", height: "1px" }} />
      <div
        onClick={clearAllFilters}
        className={`${styles.clearAll} ${customClass}`}
      >
        <img src={CrossIcon} alt="Bytepad-cross-icon" />
        <span>Clear</span>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sessionList: state.filter.examYears,
    examTypeList: state.filter.examTypes,
    contentTypeList: state.filter.paperTypes,

    selectedSesionList: state.filter.selectedSessions,
    selectedExamTypeList: state.filter.selectedExamTypes,
    selectedContentTypeList: state.filter.selectedPaperTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeContentType: (list) => dispatch(actionCreators.changePaperType(list)),
    changeExamType: (list) => dispatch(actionCreators.changeExamType(list)),
    changeSession: (list) => dispatch(actionCreators.changeSession(list)),
    clearAllFilters: () => dispatch(actionCreators.clearAllFilters()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
