import React, { useState } from "react";
import { Checkbox } from "antd";
import { connect } from "react-redux";
import styles from "./DropdownSidebar.module.css";
import * as actionCreators from "../../store/actions/actions";
import CrossIcon from "../../assets/images/cross.svg";

const DropdownSidebar = (props) => {
  const [sessions, setSessions] = useState(props.sessionList);
  const [examTypes, setExamTypes] = useState(props.examTypeList);
  const [contentTypes, setContentTypes] = useState(props.contentTypeList);

  const changeHandler = (e, type, subType) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (type === "ContentType") {
      let newList = [...contentTypes];
      let toggleType = subType;

      newList.map((contentType) => {
        if (contentType.contType === toggleType) {
          contentType.checked = e.target.checked;
        }
        return null;
      });

      let selectedContentType = [];
      newList.map((contenttype) => {
        if (contenttype.checked) {
          selectedContentType.push(contenttype.contType);
        }
        return null;
      });

      props.changeContentType(selectedContentType);
      setContentTypes(newList);
    }

    // FOR HANDLING EXAM-TYPE CHANGES
    else if (type === "ExamType") {
      let newList = [...examTypes];
      let toggleType = subType;

      newList.map((examType) => {
        if (examType.exam === toggleType) {
          examType.checked = e.target.checked;
        }
        return null;
      });

      let selectedExamTypes = [];
      newList.map((examType) => {
        if (examType.checked) {
          selectedExamTypes.push(examType.exam);
        }
        return null;
      });

      props.changeExamType(selectedExamTypes);
      setExamTypes(newList);
    }

    //FOR HANDLING SESSION-CHANGES
    else if (type === "Session") {
      let newSessions = [...sessions];
      let toggleYear = subType;

      newSessions.map((session) => {
        if (session.year === toggleYear) {
          session.checked = e.target.checked;
        }
        return null;
      });

      let selectedSessions = [];
      newSessions.map((session) => {
        if (session.checked) {
          selectedSessions.push(session.year);
        }
        return null;
      });

      props.changeSession(selectedSessions);
      setSessions(newSessions);
    }
  };

  const clearAllContentTypes = () => {
    props.changeContentType([]);
    props.clearAllContentTypes();
  };
  const clearAllExamTypes = () => {
    props.changeExamType([]);
    props.clearAllExamTypes();
  };
  const clearAllSessionTypes = () => {
    props.changeSession([]);
    props.clearAllSessionTypes();
  };

  let customClass1 = null;
  let customClass2 = null;
  let customClass3 = null;
  if (props.selectedContentTypeList.length === 0) {
    customClass1 = styles.disableClearAll;
  }
  if (props.selectedExamTypeList.length === 0) {
    customClass2 = styles.disableClearAll;
  }
  if (props.selectedSesionList.length === 0) {
    customClass3 = styles.disableClearAll;
  }

  if (props.filter === "Type") {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", padding: "10px" }}
      >
        {contentTypes.map((contentType, index) => {
          return (
            <Checkbox
              key={index}
              checked={contentType.checked}
              onChange={(e) =>
                changeHandler(e, "ContentType", contentType.contType)
              }
              className={styles.option}
              style={
                contentType.checked
                  ? { color: "#4385F5" }
                  : { color: "#546076" }
              }
            >
              {contentType.contType}
            </Checkbox>
          );
        })}

        <hr style={{ height: "1px", margin: "0px", marginBottom: "12px" }} />
        <div
          onClick={clearAllContentTypes}
          className={`${styles.clearAll} ${customClass1}`}
        >
          <img src={CrossIcon} alt="Bytepad-cross-icon" />
          <span>Clear</span>
        </div>
      </div>
    );
  } else if (props.filter === "Exam") {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", padding: "10px" }}
      >
        {examTypes.map((examType, index) => {
          return (
            <Checkbox
              key={index}
              checked={examType.checked}
              onChange={(e) => changeHandler(e, "ExamType", examType.exam)}
              className={styles.option}
              style={
                examType.checked ? { color: "#4385F5" } : { color: "#546076" }
              }
            >
              {examType.exam}
            </Checkbox>
          );
        })}

        <hr style={{ height: "1px", margin: "0px", marginBottom: "12px" }} />
        <div
          onClick={clearAllExamTypes}
          className={`${styles.clearAll} ${customClass2}`}
        >
          <img src={CrossIcon} alt="Bytepad-cross-icon" />
          <span>Clear</span>
        </div>
      </div>
    );
  } else if (props.filter === "Session") {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", padding: "10px" }}
      >
        {sessions.map((session, index) => {
          return (
            <Checkbox
              key={index}
              checked={session.checked}
              onChange={(e) => changeHandler(e, "Session", session.year)}
              className={styles.option}
              style={
                session.checked ? { color: "#4385F5" } : { color: "#546076" }
              }
            >
              {session.year}
            </Checkbox>
          );
        })}

        <hr style={{ height: "1px", margin: "0px", marginBottom: "12px" }} />
        <div
          onClick={clearAllSessionTypes}
          className={`${styles.clearAll} ${customClass3}`}
        >
          <img src={CrossIcon} alt="Bytepad-cross-icon" />
          <span>Clear</span>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    sessionList: state.filter.examYears,
    examTypeList: state.filter.examTypes,
    contentTypeList: state.filter.paperTypes,

    selectedSesionList: state.filter.selectedSessions,
    selectedExamTypeList: state.filter.selectedExamTypes,
    selectedContentTypeList: state.filter.selectedPaperTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeContentType: (list) => dispatch(actionCreators.changePaperType(list)),
    changeExamType: (list) => dispatch(actionCreators.changeExamType(list)),
    changeSession: (list) => dispatch(actionCreators.changeSession(list)),

    clearAllContentTypes: () => dispatch(actionCreators.clearAllContentTypes()),
    clearAllExamTypes: () => dispatch(actionCreators.clearAllExamTypes()),
    clearAllSessionTypes: () => dispatch(actionCreators.clearAllSessionTypes()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownSidebar);
