import React from "react";
import QRCode from "qrcode.react";
import "./QRScanner.css";
import CopyIcon from "../../assets/images/CopyIcon.svg";

const QRScanner = (props) => {

  const copyLink = () => {
    // For Chrome
    if (navigator.clipboard !== undefined) {
      navigator.clipboard
        .writeText(props.link)
        .then((res) => {
          props.handleCopying();
        })
        .catch((err) => {});
    } 
    else if (window.clipboardData) {
      // Internet Explorer
      window.clipboardData.setData("Text", props.link);
      props.handleCopying();
    }
  };

  return (
    <div className="qrScanner">
      <h6 className="qrHeader">Share paper</h6>

      <div className="qrCopy" onClick={copyLink}>
        <img alt="Bytepad-doc-qr-code" src={CopyIcon} />
        {props.isCopied ? "Link copied!" : "Copy link"}
      </div>

      <h6 className="qrOr">Or</h6>

      <QRCode width={100} className="qrCode" value={props.link} />

      <h6 className="qrBottom">Scan with Bytepad app to view on mobile</h6>
    </div>
  );
};

export default QRScanner;
